
.header {
  text-align: left;
  padding:20px 5%;
  background: #FFFFFF;
  border-bottom:1px solid #cfcfcf;
}
.banner{
  height:48%;
  position: relative;
}
.banner img{
  margin-left:auto;
  float:right;
  width:100%;
}
.banner .float-banner{
  position:absolute;
  z-index:1;
  top:25%;
  width:580px;
  left:5%;
}
.float-banner img{
  float: none;
  margin-bottom: 65px;
}
.banner .float-slogan{
  font-family: Apercu Pro;
  font-size: 48px;
  line-height: 55px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: #231F20;
}
.banner .float-body{
  font-family: Apercu Pro;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  color: #231F20;
}
.price{
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #8223D2
}
.banner .anchor-btn,.banner .anchor-btn:focus, .submit-btn button, .submit-btn button:focus, .submit-btn button:active{
  font-family: Apercu Pro;
  background: #8223D2;
  border-radius: 100px;
  font-size: 16px;
  line-height: 150%;
  border:0;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  outline: 0;
  padding:10px 40px;
}
.covers{
  padding-top:100px;
  display:block;
  padding-bottom:70px;
}

.covers-title{
  width: 827px;
  height: 110px;
  font-family: Apercu Pro;
  font-size: 48px;
  line-height: 55px;
  align-items: center;
  text-align: center;
  letter-spacing: -1px;
  color: #231F20;
  margin:0  auto 45px auto;
}
.covers-body{
  display:block;
  text-align: center;
}
.covers-body .row{
  padding:0 15px;
}
.block{
  width: 100%;

  background: #D2FA46;
  border-radius: 30px;
  /*display:inline-block;*/
  margin-bottom:30px;
  padding:20px 20px 10px 50px;
  display: block;
  position: relative;
  min-height:140px;
  /*justify-content: center;
  align-items: center; */
}
.block > img{
  position: absolute;
  left:-40px;
  z-index: 1;
  top:20px;
}
.right-block{
  padding: 20px 50px 10px 20px;
}
.right-block > img{
  right:-40px;
  left:auto;
}
.block-title{
  font-family: Apercu Pro;
  font-size: 18px;
  line-height: 20px;
  min-height:44px;
  width:100%;
  margin-bottom:10px;
  position: relative;
  display:block;
  text-align: left;
  clear:both;
}
.block-img{
  display:block;
  text-align: left;
  clear:both;
  cursor: hand;
}

.block-body{
  font-family: Apercu Pro;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  min-height: 68px;
}
.right-block .block-body{
  padding-right:10px;
}
.covers-left, .covers-right{
  position: relative;
}
.covers-left img,.covers-right img{
  position: absolute;
  bottom:20px;
}
.covers-right img{
  right:0;
  bottom:0px;
}
.form{
  padding-top:60px;
  padding-bottom:60px;
  background:#F0F0F5;
}
.form-left{
  text-align: center;
}
.form-title{
  font-family: Apercu Pro;
  font-size: 32px;
  line-height: 42px;
}
.form-subtitle{
  font-family: Apercu Pro;
  font-size: 22px;
  line-height: 30px;
  margin-top:10px;
  margin-bottom:20px;
}
.tnc,.submit-btn{
  display:block;
  text-align: left;
  margin-bottom:10px;
  padding-left:19px;
  white-space: break-spaces;
}
.who {
  padding: 60px 0;
}
.who h5{
  font-family: Apercu Pro;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  margin-bottom:80px;
}
.who-block{
  text-align: center;
  font-family: Apercu Pro;
  font-size: 24px;
  line-height: 135%;
}
.who-block img{
  margin-bottom:20px;
}
.who-title{
  font-weight:bold;
}
.review{
  padding: 60px 0;
  background:#ECECF1;
  text-align: center;
}
.review .review-title{
  display:block;
}
.review h5{
  font-family: Apercu Pro;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  margin-bottom:30px;
}
.review .review-block{
  background: #FFFFFF;
  box-shadow: 6px 6px 20px rgba(117, 114, 114, 0.25);
  border-radius: 30px;
  padding:20px 0;
}
.review-block p{
  margin-bottom:5px;
}
.review h6{
  font-family: Apercu Pro;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom:50px;
}

.footer{
  background: #000000;
  padding:60px 5% 30px 5%;
}
.footer p{
  width:100%;
  display:block;
  clear:both;
  margin-bottom:30px;
  color:#fff;
}
.footer .seals img{
  margin-right:15px;
  margin-bottom:10px;
}
.confirm-title{
  font-family: Apercu Pro;
  font-size: 20px;
  line-height: 150%;
}
.confirm-body{
  font-family: Apercu Pro;
  font-size: 16px;
  line-height: 150%;
}
.confirm-cancel-btn, .confirm-cancel-btn:hover{
  background: #fff;
  border-radius: 10012px;
  font-family: Apercu Pro;
  font-size: 14px;
  line-height: 150%;
  border:1px solid #8223D2;
  color:#8223D2;
  width:120px;
}
.confirm-ok-btn, .confirm-ok-btn:hover{
  background: #8223D2;
  border-radius: 10012px;
  font-family: Apercu Pro;
  font-size: 14px;
  line-height: 150%;
  width:120px;
  color: #fff;
  margin-left:10px;
}
.modal-content{
  padding:20px;
}
.confirm-label{
  font-family: Apercu Pro;
  font-size: 16px;
  line-height: 150%;
}
.confirm-box{
  background: #FFFFFF;
  font-family: Apercu Pro;
  font-size: 16px;
  line-height: 150%;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 100px;
  padding:4px 10px;
  margin-bottom:10px;
}
.confirm-form{
  margin-bottom:20px;
}
.confirm-modal {
  width: 650px;
  max-width: 650px;
}
.confirm-btn-group{
  text-align: right;
  position: aboslute;
}
.confirm-btn-group button{
  top:30%;
  position: relative;
}
.thankyou-btn,.thankyou-btn:hover{
  background: #6EFAC3;
  border-radius: 9999px;
  font-family: Apercu Pro;
  font-size: 16px;
  color:#000;
  padding:4px 10px;
}
.form-control.is-invalid, .was-validated .form-control:invalid, .form-control.is-valid, .was-validated .form-control:valid{
  background-image:none;
}
.footer-section{
  width:100%;
}
.copyright{
  color:#fff;
  float:left;
}
.footer-links a{
  float:right;
  color:#fff;
  margin-left:45px;
  float:right;
}

.form-check-label a{
  color:#000;
}

.was-validated .form-check-input:invalid~.form-check-label a{
  color: #dc3545;
}
.was-validated .form-check-input:invalid~.form-check-label a:hover{
  text-decoration:underline;
}
.was-validated .form-check-input:valid~.form-check-label {
  color: #000;
}
.spinner{
  width:15px;
  position: absolute;
  top:8px;
  margin-left:5px;
}

@media screen and (max-width: 480px) {
  .header > img{
    max-width: 110px;
    max-height: 25px;
  }
  .banner{
    width:auto;
    height:700px;
  }
  .banner > img{
    float: none;
    margin:0;
    display: block;
    position: absolute;
    top: 290px;
    right: 0;
    width: 210vw;
  }
  .banner .float-banner {
    position: absolute;
    z-index: 1;
    top: 8%;
    width: 100%;
    left:0%;
    text-align: center;
  }
  .float-banner img {
    float: none;
    width:240px;
    margin-bottom: 35px;
  }
  .banner .float-slogan {
    font-size: 24px;
    line-height: 135%;
    width:270px;
    margin:0 auto;
  }
  .banner .float-body {
    font-size:16px;
    width: 300px;
    margin:20px auto;
    line-height:22px;
  }
  .price{
    font-size:16px;
    line-height:22px;
  }
  .covers-left, .covers-right{
    display:none;
  }
  .covers {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .covers-title{
    font-size: 24px;
    line-height: 32px;
    width:302px;
  }
  .form-left img{
    width:300px;
  }
  .form-title {
    font-size: 24px;
    line-height: 36px;
    margin:25px 0 15px 0;
  }
  .form-subtitle {
    font-size:16px;
    line-height: 25px;
  }
  .submit-btn.row{
    text-align: center;
  }
  .who h5{
    font-size: 24px;
    line-height: 30px;
    margin-left: auto;
    margin-right:auto;
  }
  .who-block p{
    font-size: 16px;
    line-height: 150%;
  }
  .review h5{
    font-size: 24px;
    line-height: 30px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review h6 {
    font-size: 16px;
    line-height: 20px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review .review-block{
    margin-bottom:30px;
  }
  .block{
    margin-bottom:80px;
    padding: 70px 20px 20px 20px;
  }
  .block-title, .block-body{
    text-align: center;
  }
  .block > img {
    position: absolute;
    left: 40%;
    top: -50px;
    z-index: 1;
  }
  .block-img {
    text-align: center;
  }
  .footer-links a{
    margin-top:10px;
    margin-right:45px;
    margin-left:0px;
  }
  .confirm-modal {
    width: auto;
    max-width: 100%;
  }
  .confirm-btn-group {
    text-align:center;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .header > img{
    max-width: 110px;
  }
  .banner{
    width:auto;
    height:auto;
  }
  
  .banner .float-banner {
    position: absolute;
    z-index: 1;
    top: 15%;
    width: 380px;
    left:5%;
    text-align: left;
  }
  .float-banner img {
    float: none;
    width:240px;
    margin-bottom: 35px;
  }
  .banner .float-slogan {
    font-size: 24px;
    line-height: 135%;
    width:270px;
  }
  .banner .float-body {
    font-size:16px;
    width: 300px;
    margin:20px 0px;
    line-height:22px;
  }
  .price{
    font-size:16px;
    line-height:22px;
  }
  .covers-left, .covers-right{
    display:none;
  }
  .covers {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .covers-title{
    font-size: 24px;
    line-height: 32px;
    width:302px;
  }
  .form-left img{
    width:300px;
  }
  .form-title {
    font-size: 24px;
    line-height: 36px;
    margin:25px 0 15px 0;
  }
  .form-subtitle {
    font-size:16px;
    line-height: 25px;
  }
  .submit-btn.row{
    text-align: center;
  }
  .who h5{
    font-size: 24px;
    line-height: 30px;
    width:350px;
    margin-left: auto;
    margin-right:auto;
  }
  .who-block p{
    font-size: 16px;
    line-height: 150%;
  }
  .review h5{
    font-size: 24px;
    line-height: 30px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review h6 {
    font-size: 16px;
    line-height: 20px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review .review-block{
    margin-bottom:30px;
  }
  .block{
    margin-bottom:80px;
    padding: 70px 20px 20px 20px;
  }
  .block-title, .block-body{
    text-align: center;
  }
  .block > img {
    position: absolute;
    left: 40%;
    top: -50px;
    z-index: 1;
  }
  .block-img {
    text-align: center;
  }
  .footer-links{
    float:left;
    display:block;
  }
  .footer-links a{
    margin-top:10px;
    margin-right:45px;
    margin-left:0px;
  }
  .confirm-modal {
    width: auto;
    max-width: 100%;
  }
  .confirm-btn-group {
    text-align:center;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .header > img{
    max-width: 110px;
  }
  .banner{
    width:auto;
    height:auto;
  }
  
  .banner .float-banner {
    position: absolute;
    z-index: 1;
    top: 15%;
    width: 380px;
    left:5%;
    text-align: left;
  }
  .float-banner img {
    float: none;
    width:240px;
    margin-bottom: 35px;
  }
  .banner .float-slogan {
    font-size: 24px;
    line-height: 135%;
    width:270px;
  }
  .banner .float-body {
    font-size:16px;
    width: 300px;
    margin:20px 0px;
    line-height:22px;
  }
  .price{
    font-size:16px;
    line-height:22px;
  }
  .covers-left, .covers-right{
    display:none;
  }
  .covers {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .covers-title{
    font-size: 24px;
    line-height: 32px;
    width:302px;
  }
  .form-left img{
    width:300px;
  }
  .form-title {
    font-size: 24px;
    line-height: 36px;
    margin:25px 0 15px 0;
  }
  .form-subtitle {
    font-size:16px;
    line-height: 25px;
  }
  .submit-btn.row{
    text-align: center;
  }
  .who h5{
    font-size: 24px;
    line-height: 30px;
    width:350px;
    margin-left: auto;
    margin-right:auto;
  }
  .who-block p{
    font-size: 16px;
    line-height: 150%;
  }
  .review h5{
    font-size: 24px;
    line-height: 30px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review h6 {
    font-size: 16px;
    line-height: 20px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review .review-block{
    margin-bottom:30px;
  }
  .block{
    margin-bottom:80px;
    padding: 70px 20px 20px 20px;
  }
  .block-title, .block-body{
    text-align: center;
  }
  .block > img {
    position: absolute;
    left: 40%;
    top: -50px;
    z-index: 1;
  }
  .block-img {
    text-align: center;
  }
  .copyright{
    color:#fff;
    display:inline-block;
  }
  .footer-links{
    display:inline-block;
    float: right;
  }
  .footer-links a{
    margin-top:0px;
    margin-right:0px;
    margin-left:45px;
  }
  .confirm-modal {
    width: auto;
    max-width: 100%;
  }
  .confirm-btn-group {
    text-align:center;
  }

}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .header > img{
    max-width: 110px;
  }
  .banner{
    width:auto;
    height:auto;
  }
  
  .banner .float-banner {
    position: absolute;
    z-index: 1;
    top: 15%;
    width: 380px;
    left:5%;
    text-align: left;
  }
  .float-banner img {
    float: none;
    width:240px;
    margin-bottom: 35px;
  }
  .banner .float-slogan {
    font-size: 24px;
    line-height: 135%;
    width:270px;
  }
  .banner .float-body {
    font-size:16px;
    width: 300px;
    margin:20px 0px;
    line-height:22px;
  }
  .price{
    font-size:16px;
    line-height:22px;
  }
  .covers-left img, .covers-right img {
    width: 100%;
}
  .covers {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .covers-title{
    font-size: 24px;
    line-height: 32px;
    width:302px;
  }
  .form-left img{
    width:300px;
  }
  .form-title {
    font-size: 24px;
    line-height: 36px;
    margin:25px 0 15px 0;
  }
  .form-subtitle {
    font-size:16px;
    line-height: 25px;
  }
  .submit-btn.row{
    text-align: center;
  }
  .who h5{
    font-size: 24px;
    line-height: 30px;
    width:350px;
    margin-left: auto;
    margin-right:auto;
  }
  .who-block p{
    font-size: 16px;
    line-height: 150%;
  }
  .review h5{
    font-size: 24px;
    line-height: 30px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review h6 {
    font-size: 16px;
    line-height: 20px;
    width:300px;
    margin-left:auto;
    margin-right:auto;
  }
  .review .review-block{
    margin-bottom:30px;
  }
  .block{
    margin-bottom:80px;
    padding: 70px 20px 20px 20px;
  }
  .block-title, .block-body{
    text-align: center;
  }
  .block > img {
    position: absolute;
    left: 40%;
    top: -50px;
    z-index: 1;
  }
  .block-img {
    text-align: center;
  }
  .copyright{
    color:#fff;
    display:inline-block;
  }
  .footer-links{
    display:inline-block;
    float: right;
  }
  .footer-links a{
    margin-top:0px;
    margin-right:0px;
    margin-left:45px;
  }
  .confirm-modal {
    width: auto;
    max-width: 100%;
  }
  .confirm-btn-group {
    text-align:center;
  }

}
