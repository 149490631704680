@font-face {
  font-family: 'Apercu Pro';
  src: local('Apercu Pro'), url(./fonts/Apercu-Regular-Pro.woff2) format('woff2'); 
}

body {
  margin: 0;
  font-family: 'Apercu Pro';
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/

}
body, html { overflow-x:hidden; }